.homepage {
    width: 100%;
}

.slider {
    position: fixed;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background: #0B1325;
    border-radius: 80px 80px 0px 0px;
    z-index: 1;
}

.slideImg {
    position: absolute;
    /* top: 80px; */
    width: 100vw;
    height: 1100px;
    /* height: calc(100vh - 80px); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    filter: blur(2px);
}

.slideImg::before {
    content: "";
    position: absolute;
    inset: 0px;
    background: linear-gradient(rgba(14, 25, 49, 0.5) 0%, #0B1325 100%), linear-gradient(rgba(14, 25, 49, 0.2) 0%, transparent 100%);
    z-index: 0;
}