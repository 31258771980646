.osfHero {
    position: relative;
    width: 100%;
    height: 1100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding: 40px 24px;
    padding-top: 120px;
    margin: auto;
}

.osfHero::before {
    content: "";
    position: absolute;
    inset: 0px;
    background: linear-gradient(rgba(14, 25, 49, 0.5) 0%, #0B1325 100%), linear-gradient(rgba(14, 25, 49, 0.2) 0%, transparent 100%);
    z-index: 0;
    backdrop-filter: blur(2px);
}

.osfHeroContainer {
    z-index: 1;
    max-width: 1400px;
    margin: auto;
    padding: 12px;
    backdrop-filter: blur(25px);
    border-radius: 32px;
}

.textLogo {
    z-index: 1;
    text-align: left;
}

.pageTitle {
    color: #dd9510;
}

.image {
    z-index: 1;
    display: flex;
    justify-content: center;
}

.prototype {
    text-align: left;
}

.iframe {
    width: 375px;
    height: 810px;
    border: none;
    border-radius: 2pc;
}

@media only screen and (max-width: 992px) {
    /* .iframe {
    -moz-transform: scale(0.71);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.71);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.71);
    -webkit-transform-origin: 0 0;
    } */

    .osfHero {
        height: auto;
    }

    .osfLogo {
        max-height: 200px;
    }

    .image {
        padding: 0;
        margin-top: 16px;
    }

    .textLogo {
        padding: 0;
    }

    .prototype p {
        padding-left: 20px;
    }
}