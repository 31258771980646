.footer {
    background-color: #0E1931;
    width: 100%;
    color: white;
    text-align: left;
    padding: 60px 24px 60px 24px;
}

.footerContainer {
    list-style: none;
    display: flex;
    margin: auto;
    max-width: 1400px;justify-content: space-between;
}

.footText {
    display: flex;
    color: white;
}

.footLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.copywrite {
    display: flex;
    justify-content: center;
    width: 100%;
}

.contactLinks {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.socialLinks {
    display: flex;
}

.socialLink {
    color: white;
    display: flex;
    justify-content: center;
}

.footEmail {
    display: flex;
    justify-content: center;
    color: white;
    cursor: pointer;
}

.footPhone {
    display: flex;
    justify-content: center;
    color: white;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {
    .footerContainer {
        flex-direction: column;
        gap: 2rem;
    } 
    
    .footText {
        justify-content: center;
    }
}