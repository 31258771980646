.App {
  text-align: center;
  font-family: Urbanist, sans-serif;
  background-color: #0B1325;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App::before {
  content: '';
  position: fixed;
  height: 100vh;
  width: 100%;
  box-shadow: 0 0 0 35px black;
  border-radius: 40px;
  z-index: 3;
  top: 0;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .App-header::before {
    box-shadow: none;
  }
}

::-webkit-scrollbar {
  position: absolute;
  width: 0;
}

::-webkit-scrollbar-thumb {

}
::-webkit-scrollbar-track {
background-color: black;
}

p {
  font-size: 20px;
}

h1 {
  font-weight: 600;
  letter-spacing: 2px;
}

h2 {
  font-weight: 600;
  letter-spacing: 1px;
}

h5 {
  font-weight: 500;
  letter-spacing: 1px;
}

@media only screen and (max-width: 992px) {
  .App::before {
    box-shadow: none;
  }
}