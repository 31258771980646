header {
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    z-index: 2;
    /* padding: 24px; */
    transition: .5s;
    height: 104px;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    background: #71798B80;
    backdrop-filter: blur(10px);
    padding: 20px 24px;
}

.navBar {
    width: 100%;
    display: flex;
    /* box-shadow: 0px 3px 15px rgba(0,0,0,0.2); */
    /* background: #71798B80;
    backdrop-filter: blur(10px); */
    /* border-radius: 40px; */
    /* padding: 20px 24px; */
    /* align-items: center; */
    
    /* min-height: 80px; */
    /* height: 80px; */
    max-width: 1400px;
    margin: 0 auto;
    transition: .5s;

    align-items: center;

}

.navContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-height: 40px;
    align-items: center;
}

.navLeft {
    display: flex;
    align-items: flex-end;
    text-align: left;
    transition: .5s;
}

.navLeft h1 {
    color: white;
}

nav {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: auto;
    align-items: center;
    /* padding: 0  24px 0 24px; */
    flex: auto;
    transition: .5s;
}

.navItem {
    cursor: pointer;
    color: white;
    border-bottom: 0px;
    transition: .1s;
}

.navLink:hover {
    padding-top: 4px;
    cursor: pointer;
    color: #121F3D;
    border-bottom: 4px solid #121F3D;
    transition: .1s;
}

.active {
    border-bottom: 4px solid #dd9510;
    padding-top: 4px;
    transition: .1s;
}

.noActive {
    padding-top: 0;
    border-bottom: none;
}

.navButtin {
    color: white;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    display: none;
}

.navButtin-close {
    color: white;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    display: none;
    opacity: 0;
}

@media only screen and (max-width: 992px) {
    .navButtin {
        display: block;
        transform: translateY(-4px);
    }

    .header_height {
        height: 100vh;
        backdrop-filter: blur(10px);
    }

    .navLeft {
        display: inline;
        transition: .5s;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 1%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0;
        transition: .5s;
        margin-top: 0;
        visibility: hidden;
        opacity: 0 !important;
    }

    .visible_links {
        visibility: visible;
        height: 100%;
        transition: .5s;
        gap: 5rem;
        margin-top: 124px;
        opacity: 1 !important;
        z-index: 0;
    }

    header .responsive_nav {
        max-height: none;
        /* height: 100%; */
        /* transition: .5s; */
        /* padding: 20px 24px; */
        align-items: flex-start;
        /* backdrop-filter: blur(10px); */
    }

    .hamburger-react {
        height: 40px !important;
        width: 40px !important;
    }

    .navBar {
        padding-top: 10px;
        align-items: normal;
    }
}