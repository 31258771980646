.gamelog {
    width: 100%;
}

.mondayContainer {
    margin-top: 100px;
    width: 100%;
    height: calc(100vh - 100px);
    padding: 24px;
    justify-content: center;
    align-items: center;
}

.mondayIframe {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    border-radius: 20px;
    max-width: 1400px;
}