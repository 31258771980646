.aboutMe {
    width: 100%;
    color: white;
    text-align: left;
    padding: 140px 24px 0 24px;
}

.aboutContainer {
    display: flex;
    margin: auto;
    max-width: 1400px;
    align-items: center;
}

.headshot {
    height: 275px;
    width: calc(100% - 20px);
    border-radius: 40px;
    box-shadow: 20px 20px 0 0 #71798B;
    background-size: cover;
    background-position: center center;
    margin-bottom: 20px;
}

@media only screen and (max-width: 992px) {
    .aboutContainer {
        flex-direction: column-reverse;
    }

    .aboutRight {
        padding: 0 0 20px 0;
    }

    .aboutLeft {
        padding: 0;
    }

    .headshot {
        height: 200px;
        width: calc(100% - 20px);
        border-radius: 40px;
        box-shadow: 20px 20px 0 0 #71798B;
        background-size: cover;
        background-position: center center;
        margin-bottom: 20px;
    }
}