.resume {
    width: 100%;
    color: white;
    text-align: left;
    padding: 140px 24px 140px 24px;
}

.resumeContainer {
    display: flex;
    margin: auto;
    max-width: 1400px;
    align-items: center;
}

.resumeImg {
    height: 100%;
    width: calc(100% - 20px);
    border-radius: 40px;
    box-shadow: 20px 20px 0 0 #71798B;
    background-size: cover;
    background-position: center center;
    /* transform: translateX(-10px); */
}

.resMob {
    display: none;
}

@media only screen and (max-width: 992px) {
    .resumeContainer {
        flex-direction: column-reverse;
    }

    .resDesk {
        display: none;
    }

    .resumeImg {
        margin-top: 20px;
    }

    .resMob {
        display: block;
    }
}