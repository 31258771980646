.header {
    background-color: white;
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    text-align: left;
    padding: 0 24px;
    z-index: 2;
}

.headerRow {
    display: flex;
    margin: auto;
    max-width: 1400px;
    height: 100%;
}

.nameLogo {
    background: #121F3D;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    z-index: 3;
    margin-right: 56px;
    /* padding-left: 24px; */
    box-shadow: 35px 0 0 #71798B;
    border-radius: 0px 132px 0px 0px;
    transition: 1s;
}

.name {
    padding-left: 40px;
    color: white;
}

.headerRow::before {
    content: '';
    position: absolute;
    background: #121F3D;
    width: 27%;
    left: 0;
    height: 100%;
    z-index: 2;
}

.navLinks {
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: auto;
    align-items: center;
    /* padding: 0  24px 0 24px; */
    flex: auto;
}

.navLink {
    display: flex;
    align-items: center;
    height: 100%;
}

.navLink:hover {
    padding-top: 4px;
    cursor: pointer;
    color: #121F3D;
    border-bottom: 4px solid #121F3D;
}

.active {
    color: #121F3D;
    border-bottom: 4px solid #dd9510;
    padding-top: 4px;
}

.noActive {
    padding-top: 0;
    border-bottom: none;
}

.navBtn {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: red;
    visibility: hidden;
    opacity: 0;
}

