.catHero {
    position: relative;
    width: 100%;
    height: 1100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding: 40px 24px;
    padding-top: 120px;
    margin: auto;
}

.catHero::before {
    content: "";
    position: absolute;
    inset: 0px;
    background: linear-gradient(rgba(14, 25, 49, 0.5) 0%, #0B1325 100%), linear-gradient(rgba(14, 25, 49, 0.2) 0%, transparent 100%);
    z-index: 0;
    backdrop-filter: blur(2px);
}

.catHeroContainer {
    z-index: 1;
    max-width: 1400px;
    margin: auto;
    padding: 12px;
    backdrop-filter: blur(25px);
    border-radius: 32px;
}

.textLogo {
    z-index: 1;
    text-align: left;
}

.catLogo {
    max-width: 100%;
    max-height: 300px;
}

.pageTitle {
    color: #dd9510;
}

.rightText {
    z-index: 1;
    text-align: left;
    justify-content: center;
}

.catImage {
    border-radius: 20px;
    text-align: left;
    max-width: 100%;
}

.iframe {
    width: 375px;
    height: 810px;
    border: none;
    border-radius: 2pc;
}

@media only screen and (max-width: 992px) {
    .catLogo {
        max-width: 100%;
        max-height: 200px;
    }

    .catHero {
        height: auto;   
    }

    .textLogo {
        padding: 0;
        padding-bottom: 12px;
    }

    .rightText {
        padding: 0;
    }
    
    .mobHeight {
        max-height: none !important;
    }
}