.container {
    max-width: 1400px;
    margin: auto;
    padding: 0;
}

.portfolioGallery {
    width: 100%;
    color: white;
    text-align: left;
    padding: 140px 24px 0 24px;
}

.galleryGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-box-align: center;
    align-items: center;
    gap: 24px;
    padding-top: 24px;
    border-radius: 10px;
}

.portfolioCard {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 20px;
    max-height: 340px;
    transition: all 0.2s ease-in-out 0s;
    background-size: cover;
    background-position: center center;
    text-decoration: none;
    padding: 12px;
    width: 100%;
    overflow: hidden;
}

.portfolioCard::before {
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 0;
}

.portfolioCard:hover {
    scale: 102%;
    cursor: pointer;
}

.logo {
    height: 200px;
    z-index: 1;
}

h2 {
    z-index: 1;
    color: white;
}

a {
    color: #0B1325;
    text-decoration: none;
}

a:hover {
    color: white;
}

@media only screen and (max-width: 992px) {
    .galleryGrid {
        grid-template-columns: 1fr;
    }
    
    .logo {
        height: 100px;
    }
}