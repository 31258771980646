.hero {
    /* height: calc(100vh - 80px); */
    width: 100%;
    /* transform: translateY(80px); */
    padding: 0 24px;
    z-index: 1;
    
}

/* @media screen and (max-width: 1264px) {
    .heroLeft {
        padding-left: 24px;
    }
} */

.textCenter {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100%;
    max-width: 1400px;
    align-items: center;
    justify-content: center;
}

.textCenter::before {
    content: "";
    height: 100%;
    position: absolute;
    inset: 0px;
    background: linear-gradient(rgba(14, 25, 49, 0.7) 0%, #0B1325 100%), linear-gradient(rgba(14, 25, 49, 0.2) 0%, transparent 100%);
    z-index: 0;
}

.skills {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    max-width: 1400px;
    margin-top: 75px;
}

.skillLogo {
    max-height: 75px;
}

.arrowSkills {
    display: flex;
    flex-direction: column;
}

/* .heroLeft::before {
    content: '';
    position: absolute;
    background: linear-gradient(#0E1931e0, #0B1325 100%), linear-gradient(rgba(14, 25, 49, 0.2) 0%, transparent 100%);
    width: 1000px;
    height: 100%;
    transform: translateX(-1250px);
} */

@media screen and (max-width: 1246px) {
    .heroLeft::before {
        content: '';
        position: absolute;
        left: 0;
        background: linear-gradient(#0E1931e0, #0B1325 100%), linear-gradient(rgba(14, 25, 49, 0.2) 0%, transparent 100%);
        width: 1274px;
        height: 100%;
    }
}

.textContainer {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: left;
}

.heroArrow {
    position: relative;
    display: flex;
    background: white;
    max-width: 1400px;
    padding: 12px;
    height: 100px;
    align-items: center;
    justify-content: center;
    z-index: 0;
    border-radius: 20px 20px 0 0;
    margin: auto;
    transition: all 0.2s ease-in-out 0s;
    transform: translateY(-100px);
}

.heroArrow:hover {
    color: white;
    background: #414C64;
    cursor: pointer;
    height: 120px;
    transform: translateY(-120px);
}

@media screen and (max-width: 1264px) {
    .heroArrow {
        margin: 0 40px;
    }
}

@media only screen and (max-width: 500px) {
    .heroArrow {
        margin: 0;
        height: 80px;
        transform: translateY(-80px);
    }

    .heroArrow:hover {
        height: 100px;
        transform: translateY(-100px);
    }

    .skills {
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
    }

    .skillLogo {
        max-height: 50px;
    }

    .skillLogo:last-child:nth-last-child(odd) {
        grid-column: auto / span 2;
      }
  }