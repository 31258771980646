.ssHero {
    position: relative;
    width: 100%;
    height: 1100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding: 40px 24px;
    padding-top: 120px;
    margin: auto;
}

.ssHero::before {
    content: "";
    position: absolute;
    inset: 0px;
    background: linear-gradient(rgba(14, 25, 49, 0.5) 0%, #0B1325 100%), linear-gradient(rgba(14, 25, 49, 0.2) 0%, transparent 100%);
    z-index: 0;
    backdrop-filter: blur(2px);
}

.ssHeroContainer {
    z-index: 1;
    max-width: 1400px;
    margin: auto;
    padding: 12px;
    backdrop-filter: blur(25px);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
}

.textLogo {
    z-index: 1;
    text-align: left;
}

.ssLogo {
    max-width: 100%;
    max-height: 100%;
}

.pageTitle {
    color: #dd9510;
}

.image {
    z-index: 1;
    display: flex;
    justify-content: center;
    border-radius: 20px;
}

.prototype {
    text-align: left;
}

.iframe {
    width: 375px;
    height: 810px;
    border: none;
    border-radius: 2pc;
}

@media only screen and (max-width: 992px) {
    .ssLogo {
        max-width: 100%;
        max-height: 200px;
    }

    .ssHero {
        height: auto;   
    }
}