.osfDesigns {
    position: relative;
    width: 100%;
    text-align: left;
    padding: 80px 24px 80px 24px;
}

.osfDesignsContainer {
    max-width: 1400px;
    margin: auto;
}

.osfCarouselContainer {
    width: 100%;
    min-height: 300px;
    position: relative;
    display: flex;
}

.osfCarousel {
    width: 100%;
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
}

.osfDesign {
    min-width: 25%;
    overflow: hidden;
    margin: auto;
}

.osfDesignImg {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: 40px;
    padding: 5px;
}

.osfChevron {
    font-size: 3rem;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
}